<template>
    <div>
        <base-header :base-title="$t('member.title')"></base-header>
        <!-- begin:: Content -->
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <template
                                v-if="checkPermission('LoyaltyMembersResource.POST.store')">
                                &nbsp;&nbsp;
                                <base-button-modal
                                    :button-text="modalText"
                                    :button-icon="modalIcon"
                                    :button-name="modalName"
                                    :onButtonModalClicked="showModal"
                                ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <crud-table
                        ref="crudTable"
                        :table-options="tableOptions"
                        :repository="repository"
                        :permission="permission"
                        :event="fetch"
                        @on-click-edit="onClickEdit"
                        @on-click-delete="onClickDelete"
                        @on-click-view="onClickView"
                    ></crud-table>
                </div>
                <form-modal v-if="isShowModal" @event="fetch"></form-modal>
            </div>
        </div>
        <!-- end:: Content -->
    </div>
</template>

<script>
import BaseTitle from "./../../../components/_base/BaseTitle";
import BaseHeader from "./../../../components/_base/BaseHeader";
import BaseButtonModal from "./../../../components/_base/BaseButtonModal";
import CrudTable from "./../base/crud-table/crudTable";
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import LoadSpinner from "./../../../components/_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
import { mapState, mapMutations, mapActions } from "vuex";
const memberRepository = RepositoryFactory.get("memberRepository");

export default {
    components: {
        CrudTable,
        BaseTitle,
        BaseHeader,
        BaseButtonModal,
        FormModal: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(import("./FormModal.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        vx.dataTiers = []; //this.getDataTiers();

        return {
            headTitle: this.$t("member.title"),
            modalText: this.$t("member.add"),
            modalIcon: "flaticon2-add-1",
            repository: memberRepository,
            permission: {
                edit: this.checkPermission(
                    "LoyaltyMembersResource.PUT.update.Integer.key"
                ),
                delete: this.checkPermission(
                    "LoyaltyMembersResource.DELETE.destroy.Integer.key"
                ),
                view: this.checkPermission(
                    "LoyaltyMembersResource.GET.show.Integer.key"
                )
            },
            tableOptions: {
                name: "CRUD_TABLE",
                columns: [
                    "id",
                    "name_on_card",
                    "member_no",
                    "point",
                    "loyalty",
                    "joined_date",
                    "user_id",
                    "loyalty_tier_name",
                    "actions"
                ],
                sortable: [
                    "id",
                    "name_on_card",
                    "member_no",
                    "point",
                    "loyalty",
                    "joined_date",
                    "user_id",
                    "loyalty_tier_id"
                ],
                filterable: false,
                filterByColumn: true,
                orderBy: { column: 'id', ascending:false },
                customColumn: {
                    loyalty_tier_name: 'loyalty_tier_id',
                },
                headings: {
                    id: "ID",
                    name_on_card: this.$t("member.name_on_card"),
                    member_no: this.$t("member.member_no"),
                    point: this.$t("member.point"),
                    loyalty: this.$t("member.loyalty"),
                    joined_date: this.$t("member.joined_date"),
                    user_id: 'User Id', // this.$t("member.user_id"),
                    loyalty_tier_name: this.$t("member.loyalty_tier")
                },
                columnsFilter: [
                    {
                        title:'id',
                        key:'id',
                        type:"number"
                    },
                    {
                        title:this.$t('member.name_on_card'),
                        key:"name_on_card",
                        type:"text"
                    },
                    {
                        title:this.$t('member.member_no'),
                        key:"member_no",
                        type:"text"
                    },
                    {
                        title:this.$t('member.point'),
                        key:"point",
                        type:"number"
                    },
                    {
                        title:this.$t('member.loyalty'),
                        key:'loyalty',
                        type:"number"
                    },
                    {
                        title: 'Daterange', // this.$t('member.joined_date'),
                        key:'joined_date',
                        type:"date"
                    },
                    {
                        // title:this.$t('member.user_id'),
                        title: 'User Id',
                        key:'user_id',
                        type:"number"
                    },
                    // {
                    //     title:this.$t('member.loyalty_tier'),
                    //     key:'loyalty_tier_name',
                    //     type:"number",
                    // }

                ],
                customFilters: ['loyalty_tier_name'],
                templates: {
                    joined_date: function(e, row) {
                        return moment(row.joined_date).format("DD MMM YYYY");
                    }
                },
            }
        };
    },
    watch: {
        isShowModal: function(isTrue) {
            if (!isTrue) {
                this.setEditData(null);
                let modalBackdrop = document.getElementsByClassName(
                    "modal-backdrop"
                )[0];
                if (!_.isUndefined(modalBackdrop)) {
                    modalBackdrop.parentNode.removeChild(modalBackdrop);
                }
            }
        },
        shouldRefreshTable: function(newValue, oldValue) {

            if (newValue === true) {
                this.$refs.crudTable.refreshTable();
            }
        }
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            isLoaded: state => state.isLoaded,
            table: state => state.table,
            isShowModal: state => state.isShowModal,
            showModalMode: state => state.showModalMode,
            shouldRefreshTable: state => state.shouldRefreshTable
        })
    },
    methods: {
        ...mapMutations({
            setRepository: "Crud/SET_REPOSITORY",
            refreshData: "Crud/REFRESH_TABLE",
            showModal: "Crud/SHOW_MODAL",
            hideModal: "Crud/HIDE_MODAL",
            setModal: "Crud/SET_MODAL",
            setupTable: "Crud/SETUP_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            setViewData: "Crud/SET_VIEW_DATA"
        }),
        checkPermission(method) {
            return checkPermission.isCanAccess(method);
        },
        // showModal() {},
        fetch() {

            this.refreshData(true);
            this.hideModal();
        },
        onClickEdit(data) {
            this.$store.commit("Crud/SET_EDIT_DATA", data);
            this.$store.commit("Crud/SHOW_MODAL");

        },
        onClickDelete(data) {
            this.$store.dispatch("Crud/deleteById", {
                deleteRepository: memberRepository.delete,
                id: data.id,
                name: data.name_on_card
            });
        },
        onClickView(data) {
            this.$store.commit("Crud/SET_VIEW_DATA", data);
            this.$store.commit("Crud/SHOW_MODAL");

        }
    },
    created() {
        this.setRepository(memberRepository)
        this.setModal('LoyaltyMemberModal')
        this.hideModal();
    },
    mounted() {}
};
</script>
